/* eslint-disable react/self-closing-comp */
import PropTypes from 'prop-types';

import {
  Container,
} from './styles';

export default function Line({ children }) {
  return (
    <Container>
      <span></span>
      <p>{children}</p>
      <span></span>
    </Container>
  );
}

Line.propTypes = {
  children: PropTypes.node.isRequired,
};
