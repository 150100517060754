import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-top: 16px;
  margin-bottom: 8px;

  p {
    width: 25%;

    color: #ccbfbe;
  }

  span {
    width: 100%;
    height: 1px;

    border: 1px solid #ccbfbe;
  }
`;
