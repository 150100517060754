/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import ApexChart from 'react-apexcharts';

import useChart from './useChart';

export default function Chart({ data, year }) {
  const {
    months,
  } = useChart();

  const options = {
    series: [
      {
        name: `Receita - ${year}`,
        data: [
          data?.january?.receive.toFixed(2),
          data?.february?.receive.toFixed(2),
          data?.march?.receive.toFixed(2),
          data?.april?.receive.toFixed(2),
          data?.may?.receive.toFixed(2),
          data?.june?.receive.toFixed(2),
          data?.july?.receive.toFixed(2),
          data?.august?.receive.toFixed(2),
          data?.september?.receive.toFixed(2),
          data?.october?.receive.toFixed(2),
          data?.november?.receive.toFixed(2),
          data?.december?.receive.toFixed(2),
        ],
      },
      {
        name: `Despesas - ${year}`,
        data: [
          data?.january?.cost.toFixed(2),
          data?.february?.cost.toFixed(2),
          data?.march?.cost.toFixed(2),
          data?.april?.cost.toFixed(2),
          data?.may?.cost.toFixed(2),
          data?.june?.cost.toFixed(2),
          data?.july?.cost.toFixed(2),
          data?.august?.cost.toFixed(2),
          data?.september?.cost.toFixed(2),
          data?.october?.cost.toFixed(2),
          data?.november?.cost.toFixed(2),
          data?.december?.cost.toFixed(2),
        ],
      },
    ],
    chart: {
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#43c928', '#FC5050', '#000'],
    markers: {
      size: 5,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: months,
    },
  };

  return (
    <div style={{ width: '100%' }}>
      <ApexChart options={options} height="130%" series={options.series} type="line" />
    </div>
  );
};
