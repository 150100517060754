import { Link } from 'react-router-dom';

import { TiThMenu } from 'react-icons/ti';
import { IoCloseSharp } from 'react-icons/io5';
import { useAuth } from '../../contexts/AuthProvider/useAuth';

import {
  Container,
  ItemsArea,
  ContainerMobile,
} from './styles';

import useHeader from './useHeader';

import Modal from '../Modal';
import HistoricModal from '../HistoricModal';

export default function Header() {
  const { signOut } = useAuth();

  const {
    dash,
    records,
    requests,
    products,
    myaccount,
    team,
    support,
    user,
    handleHistoricModal,
    historicModal,
    userType,
    handleOutModal,
    handleCloseOutModal,
    out,
    createCalled,
    newrequest,
    menu,
    toggleMenu,
  } = useHeader();

  return (
    <>
      <ContainerMobile onClick={toggleMenu}>
        <div className="header-container">
          <Link to="/dashboard">
            <img
              src="/icon.svg"
              alt="Logo"
            />
          </Link>

          <div>
            {menu ? <IoCloseSharp size={28} color="#fff" /> : <TiThMenu size={28} color="#fff" />}
          </div>
        </div>

        {menu || window.innerWidth > 1240 ? (
          <section id="links">
            <Link
              to="/dashboard?dash=true"
              style={{
                color: dash === 'true' && '#FFB400',
                backgroundColor: dash === 'true' && '#2B2B2B',
              }}
            >Dashboard
            </Link>
            <Link
              to="/cadastros?records=true"
              style={{
                color: records === 'true' && '#FFB400',
                backgroundColor: records === 'true' && '#2B2B2B',
              }}
            >Cadastros
            </Link>
            <Link
              to="/clientes-ativos?requests=true"
              style={{
                color: requests === 'true' && '#FFB400',
                backgroundColor: requests === 'true' && '#2B2B2B',
              }}
            >Clientes ativos
            </Link>
            <a
              onClick={createCalled}
              style={{
                color: newrequest === 'true' && '#FFB400',
                backgroundColor: newrequest === 'true' && '#2B2B2B',
              }}
            >
              Novo pedido
            </a>
            <Link
              to="/produtos?products=true"
              style={{
                color: products === 'true' && '#FFB400',
                backgroundColor: products === 'true' && '#2B2B2B',
              }}
            >Produtos
            </Link>
            {userType?.type !== 'Colaborador' && <a onClick={handleHistoricModal}>Histórico</a>}
            <Link
              id="link"
              to="/minha-conta?myaccount=true"
              style={{
                color: myaccount === 'true' && '#FFB400',
                backgroundColor: myaccount === 'true' && '#2B2B2B',
              }}
            >Minha conta
            </Link>
            {userType?.type !== 'Colaborador' && (
              <Link
                style={{
                  color: team === 'true' && '#FFB400',
                  backgroundColor: team === 'true' && '#2B2B2B',
                }}
                to="/equipe?team=true"
              >Equipe
              </Link>
            )}
            <a
            // eslint-disable-next-line max-len
            // to={userType?.type === 'ADM' ? '/adm' : (userType?.type === 'Colaborador' ? '/colaborador' : '/')}
            // replace
              onClick={handleOutModal}
            >Sair
            </a>
          </section>
        ) : (
          <></>
        )}
      </ContainerMobile>

      <Container>
        <ItemsArea>
          <section id="header">
            <Link to="/dashboard">
              <img
                src="/icon.svg"
                alt="Logo"
              />
            </Link>
            <p>
              Olá, {user?.user?.name.toUpperCase() || user?.name.toUpperCase()}
            </p>
          </section>

          {menu || window.innerWidth > 1240 ? (
            <section id="links">
              <Link
                to="/dashboard?dash=true"
                style={{
                  color: dash === 'true' && '#FFB400',
                  backgroundColor: dash === 'true' && '#2B2B2B',
                }}
              >Dashboard
              </Link>
              <Link
                to="/cadastros?records=true"
                style={{
                  color: records === 'true' && '#FFB400',
                  backgroundColor: records === 'true' && '#2B2B2B',
                }}
              >Cadastros
              </Link>
              <Link
                to="/clientes-ativos?requests=true"
                style={{
                  color: requests === 'true' && '#FFB400',
                  backgroundColor: requests === 'true' && '#2B2B2B',
                }}
              >Clientes ativos
              </Link>
              <a
                onClick={createCalled}
                style={{
                  color: newrequest === 'true' && '#FFB400',
                  backgroundColor: newrequest === 'true' && '#2B2B2B',
                }}
              >
                Novo pedido
              </a>
              <Link
                to="/produtos?products=true"
                style={{
                  color: products === 'true' && '#FFB400',
                  backgroundColor: products === 'true' && '#2B2B2B',
                }}
              >Produtos
              </Link>
              {userType?.type !== 'Colaborador' && <a onClick={handleHistoricModal}>Histórico</a>}
              <Link
                id="link"
                to="/minha-conta?myaccount=true"
                style={{
                  color: myaccount === 'true' && '#FFB400',
                  backgroundColor: myaccount === 'true' && '#2B2B2B',
                }}
              >Minha conta
              </Link>
              {userType?.type !== 'Colaborador' && (
              <Link
                style={{
                  color: team === 'true' && '#FFB400',
                  backgroundColor: team === 'true' && '#2B2B2B',
                }}
                to="/equipe?team=true"
              >Equipe
              </Link>
              )}
              <a
            // eslint-disable-next-line max-len
            // to={userType?.type === 'ADM' ? '/adm' : (userType?.type === 'Colaborador' ? '/colaborador' : '/')}
            // replace
                onClick={handleOutModal}
              >Sair
              </a>
              <Link
                style={{
                  color: support === 'true' && '#FFB400',
                  backgroundColor: support === 'true' && '#2B2B2B',
                }}
                to="https://wa.me/5517991784634?text=Ol%C3%A1%2C%20preciso%20de%20ajuda%21"
                target="_blank"
              >Suporte
              </Link>
            </section>
          ) : (
            <></>
          )}

          <div className="modal">
            {historicModal ? (
              <Modal
                visible={historicModal}
                footer={false}
                width={1000}
              >
                <HistoricModal handleClose={handleHistoricModal} />
              </Modal>
            ) : (
              <></>
            )}
          </div>

          <div className="out-modal">
            {out ? (
              <Modal
                visible={out}
                width={480}
                onCancel={handleCloseOutModal}
                onConfirm={signOut}
              >
                <h1 id="little-title">Tem certeza que deseja sair?</h1>

                <p>Ao clicar em confirmar você será redirecionado para a página de login.</p>
              </Modal>
            ) : (
              <></>
            )}
          </div>
        </ItemsArea>

      </Container>
    </>
  );
}
